'use client';
import { Form, FormControl, FormField, FormItem, Textarea } from '@/components/form';
import { Button } from '@/components/ui';
import { CommentData } from '@/hooks/fetchers/queries/useCommentsQuery';
import { zodResolver } from '@hookform/resolvers/zod';
import { SendHorizontal, X } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const FormSchema = z.object({
  comment: z.string().min(1, { message: 'Forms.comment.required' }).max(1000, { message: 'Forms.comment.max' }),
});

type Props = {
  editComment: CommentData | null;
  editCommentCancel: () => void;
  onSubmit: (content: string, editCommentId?: string) => void;
  isPending: boolean;
};

export const CommentForm = ({ onSubmit, editComment, editCommentCancel, isPending = false }: Props) => {
  const t = useTranslations();

  const form = useForm<z.infer<typeof FormSchema>>({
    mode: 'onTouched',
    resolver: zodResolver(FormSchema),
  });

  function onSubmitHandle(data: z.infer<typeof FormSchema>) {
    onSubmit(data.comment, editComment?.id);
    if (editComment) {
      handleCancelEdit();
    } else {
      form.setValue('comment', '');
      form.trigger('comment');
    }
  }

  const handleCancelEdit = () => {
    form.setValue('comment', '');
    form.trigger('comment');
    editCommentCancel();
  }

  useEffect(() => {
    if (editComment) {
      form.setValue('comment', editComment.text);
      form.trigger('comment');
    }
  }, [JSON.stringify(editComment)]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmitHandle)}
        className="relative flex w-full flex-row items-center space-x-4 border-t border-gray-200 pt-4"
      >
        <FormField
          control={form.control}
          name="comment"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <Textarea placeholder={t('Forms.comment.placeholder')} className="resize-none" {...field} />
              </FormControl>
            </FormItem>
          )}
        />
        {editComment && (
          <Button onClick={handleCancelEdit} variant="ghost" size="icon" type="button">
            <X className='text-red-500' />
          </Button>
        )}
        <Button variant="ghost" size="icon" type="submit" disabled={isPending || !form.formState.isValid}>
          <SendHorizontal />
        </Button>
      </form>
    </Form>
  );
};
