import { TagItem } from '@/components/form';

export const sortOrderValueToAPIParams = (
  value?: { id: string; desc: boolean }[]
): ({ id: string | null; sortOrder: 'ASC' | 'DESC' } | null) => {
  if (value && value[0]) {
    return {
      id: value[0].id,
      sortOrder: value[0].desc ? 'DESC' : 'ASC',
    };
  } else {
    return null;
  }
};

export const parseFilterToArrayOfTagsData = <T>(filtersData: T): TagItem[] => {
  let tags: TagItem[] = [];

  for (const key in filtersData) {
    if (Object.hasOwnProperty.call(filtersData, key)) {
      const value = filtersData[key] as string | TagItem[];
      if (Array.isArray(value)) {
        const values = value.map((tag) => {
          tag.value.type = key;
          return tag;
        });
        tags.push(...values);
      } else if (value && typeof value === 'string') {
        tags.push({
          label: value,
          value: { id: value, type: key },
        });
      }
    }
  }
  return tags;
};

export const clearFilter = <T>(filtersData: T, filterValue: TagItem, field: string): T => {
  if (Object.hasOwnProperty.call(filtersData, field)) {
    const value = (filtersData as { [key: string]: string | TagItem[] })[field];
    if (Array.isArray(value)) {
      return {
        ...filtersData,
        [field]: value.filter((tag) => tag.value.id !== filterValue.value.id),
      };
    } else if (typeof value === 'string') {
      return {
        ...filtersData,
        [field]: '',
      };
    }
  }
  return filtersData;
};
