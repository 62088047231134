'use client';
import { Colors } from '@/common/enums';
import { cn } from '@/lib/utils';
import { cva, VariantProps } from 'class-variance-authority';
import { SquareArrowOutUpRight } from 'lucide-react';

interface Props extends VariantProps<typeof variants> {
  className?: string;
  color?: Colors;
  onClickAction?: (e: React.MouseEvent<HTMLSpanElement>) => void;
  title?: string;
}

const variants = cva('grid min-w-0', {
  variants: {
    size: {
      default: 'py-1 text-sm',
      sm: 'py-0',
    },
  },
  defaultVariants: {
    size: 'default',
  },
});

export function Chips({ className, size, color = Colors.green, onClickAction, title }: Props) {
  let colorClass: string;

  switch (color) {
    case Colors.purple:
      colorClass = `border-purple-300 bg-purple-50 text-purple-600`; // #d8b4fe #faf5ff #9333ea
      break;
    case Colors.green:
      colorClass = `border-green-300 bg-green-50 text-green-600`; // #86efac #f0fdf4 #16a34a
      break;
    case Colors.blue:
      colorClass = `border-blue-300 bg-blue-50 text-blue-600`; // #93c5fd #eff6ff #2563eb
      break;
    case Colors.rose:
      colorClass = `border-rose-300 bg-rose-50 text-rose-600`; // #fda4af #fff1f2 #e11d48
      break;
    case Colors.cyan:
      colorClass = `border-cyan-300 bg-cyan-50 text-cyan-600`; // #67e8f9 #ecfeff #0891b2
      break;
    case Colors.orange:
      colorClass = `border-orange-300 bg-orange-50 text-orange-600`;
      break;
    case Colors['orange-coral']:
      colorClass = `border-[#F4743B1A] bg-[#F4743B1A] text-[#DA6A38]`;
      break;
    case Colors['orange-light']:
      colorClass = `border-[#F4A2591A] bg-[#F4A2591A] text-[#F4A259]`;
      break;
    case Colors['red-pink']:
      colorClass = `border-[#D6A2AD99] bg-[#D6A2AD1A] text-[#A3767F]`;
      break;
    case Colors.red:
      colorClass = `border-red-300 bg-red-50 text-red-600`;
      break;
    case Colors['red-orange']:
      colorClass = `border-[#FECDC9] bg-[#FEF3F2] text-[#D92D20]`;
      break;
    case Colors.pink:
      colorClass = `border-[#FCCEEE] bg-[#FDF2FA] text-[#C11574]`;
      break;

    case Colors.gray:
      colorClass = `border-[#D5D9EB] bg-[#F8F9FC] text-[#363F72]`;
      break;
    case Colors.yellow:
      colorClass = `border-[#FEDF89] bg-[#FFFAEB] text-[#DC6803]`;
      break;
    case Colors['yellow-strong']:
      colorClass = `border-[#F7CB1599] bg-[#F7CB151A] text-[#CEAA16]`;
      break;
    case Colors.slate:
      colorClass = `border-[#76BED099] bg-[#76BED01A] text-[#5C9AA9]`;
      break;
    case Colors['slate-strong']:
      colorClass = `border-[#4A4E6966] bg-[#4A4E691A] text-[#4A4E69]`;
      break;
    case Colors['slate-light']:
      colorClass = `border-[#8E9AAF66] bg-[#8E9AAF1A] text-[#8E9AAF]`;
      break;
    case Colors['slate-astral']:
      colorClass = `border-[#427AA199] bg-[#427AA11A] text-[#36617E]`;
      break;
    case Colors['slate-green']:
      colorClass = `border-[#3C6E7199] bg-[#3C6E711A] text-[#3C6E71]`;
      break;
    case Colors.teal:
      colorClass = `border-[#1B8A6B99] bg-[#1B8A6B1A] text-[#1B8A6B]`;
      break;
    case Colors.neutral:
      colorClass = `border-[#877C7C99] bg-[#877C7C1A] text-[#877C7C]`;
      break;
    case Colors.zinc:
      colorClass = `border-[#6D466B99] bg-[#6D466B1A] text-[#6D466B]`;
      break;
    case Colors.indigo:
      colorClass = `border-[#C7D7FE] bg-[#EEF4FF] text-[#3538CD]`;
      break;
    case Colors['zinc-light']:
      colorClass = `border-[#CECED699] bg-[#CECED61A] text-[#B7B7C1]`;
      break;
    case Colors.lime:
      colorClass = `border-[#A7C95799] bg-[#A7C9571A] text-[#97B54F]`;
      break;
    case Colors['lime-olive']:
      colorClass = `border-[#67943699] bg-[#6794361A] text-[#567B2F]`;
      break;
    case Colors.stone:
      colorClass = `border-[#8A5A4499] bg-[#8A5A441A] text-[#8A5A44]`;
      break;
    case Colors.amber:
      colorClass = `border-[#F4A25999] bg-[#F4A2591A] text-[#F4A259]`;
      break;
    default:
      colorClass = `border-green-300 bg-green-50 text-green-600`; // #86efac #f0fdf4 #16a34a
      break;
  }

  return title ? (
    <div className={cn('my-[1px] mr-1 inline-flex flex-row items-center rounded-full border px-3', colorClass)}>
      <div
        onClick={(e) => onClickAction?.(e)}
        className={cn(variants({ size }), onClickAction && 'mr-1 cursor-pointer', className)}
      >
        <span className="truncate">{title}</span>
      </div>
      {onClickAction && <SquareArrowOutUpRight className="ml-1 size-3" />}
    </div>
  ) : null;
}
