'use client';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { Button } from '@/components/ui';
import {
  CalendarNav,
  BookSaved,
  Home,
  Document,
  People,
  Message,
  UserSquare,
  Health,
  CalendarCircle,
  ClientAvailable,
  MessageNotif,
} from '@/components/svg';
import { cn } from '@/lib/utils';
import { LeftSideNavItem } from './left-side-nav-item';
import { useTranslations } from 'next-intl';
import { usePathname } from '@/navigation';
import { LeftSideNavSubitem } from './left-side-nav-subitem';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { useSendBirdInstance } from '@/providers';


export function LeftSideNav() {
  const { sb } = useSendBirdInstance();
  const [unreadMsgCount, setUnreadMsgCount] = useState(0);

  const handleUnreadMessages = useCallback(async () => {
    if (sb) {
      const counts = await sb.groupChannel.getTotalUnreadMessageCount();
      setUnreadMsgCount(counts);
    }
  }, [sb?.authenticate]);

  useEffect(() => {
    handleUnreadMessages();
    const getUnreadMessages = setInterval(handleUnreadMessages, 30_000); // 30s

    return () => {
      clearInterval(getUnreadMessages);
    };
  }, [handleUnreadMessages, setUnreadMsgCount]);

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isCollapseGrUsers, setIsCollapseGrUsers] = useState(true);
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const pathname = usePathname();

  const isCollapseGrUsersActive =
    pathname === '/dashboard/admin-users-list' ||
    pathname === '/dashboard/all-therapists' ||
    pathname === '/dashboard/all-guardians';

  return (
    <motion.div
      className="relative h-full min-w-16 select-none"
      initial={false}
      animate={{ width: isOpenMenu ? 238 : 74 }}
    >
      <div className="absolute right-0 h-full w-[10px] border-l border-l-zinc-200 bg-white">
        <motion.div
          initial={false}
          animate={{ rotate: isOpenMenu ? 180 : 0 }}
          onClick={() => setIsOpenMenu((prev) => !prev)}
          className="absolute left-[-10px] top-8 flex size-5 cursor-pointer items-center justify-center rounded-full border border-zinc-200 bg-white"
        >
          <ChevronRight className="text-gray-400" />
        </motion.div>
      </div>
      <ul className="ml-3 space-y-3 pt-6">
        <LeftSideNavItem openedMenu={isOpenMenu} path={'/dashboard'} icon={Home} title={t('Buttons.home')} />
        {checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_CLIENTS]) && (
          <LeftSideNavItem
            openedMenu={isOpenMenu}
            path={'/dashboard/all-clients'}
            icon={UserSquare}
            title={t('Buttons.clients')}
          />
        )}
        {checkPermissions([PERMISSIONS_TYPES.VIEW_MY_TIMESHEETS_LIST]) && (
          <LeftSideNavItem
            openedMenu={isOpenMenu}
            path={'/dashboard/my-timesheets'}
            icon={BookSaved}
            title={t('Buttons.timesheets')}
          />
        )}
        {checkPermissions([PERMISSIONS_TYPES.AVAILABLE_CLIENTS_LIST]) && (
          <LeftSideNavItem
            openedMenu={isOpenMenu}
            path={'/dashboard/available-clients'}
            icon={ClientAvailable}
            title={t('Buttons.availableClients')}
          />
        )}
        {checkPermissions([PERMISSIONS_TYPES.MY_CLIENTS_LIST]) && (
          <LeftSideNavItem
            openedMenu={isOpenMenu}
            path={'/dashboard/my-clients'}
            icon={UserSquare}
            title={t('Buttons.myClients')}
          />
        )}
        {checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_SERVICES]) && (
          <LeftSideNavItem
            openedMenu={isOpenMenu}
            path={'/dashboard/all-services'}
            icon={Health}
            title={t('Buttons.services')}
          />
        )}
        {checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_GUARDIANS]) ||
        checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_THERAPISTS]) ||
        checkPermissions([PERMISSIONS_TYPES.VIEW_ADMIN_USER_LIST]) ? (
          <li className={cn('flex cursor-pointer items-start whitespace-nowrap rounded-md')}>
            <Button
              onClick={() => {
                setIsOpenMenu(true);
                setIsCollapseGrUsers(false);
              }}
              variant={isCollapseGrUsersActive ? 'default' : 'ghost'}
              size="icon"
              className={cn(
                'min-w-9 text-gray-800 transition-all duration-300',
                isOpenMenu && 'rounded-r-none',
                isOpenMenu && !isCollapseGrUsersActive && 'hover:bg-white'
              )}
            >
              <People className={cn('w-6', isCollapseGrUsersActive ? 'text-white' : 'text-gray-400')} />
            </Button>
            {
              <div>
                <p
                  onClick={() => setIsCollapseGrUsers((prev) => !prev)}
                  className={cn(
                    'flex min-h-9 w-full flex-row items-center justify-between rounded-r-sm pl-[17px] pr-3 text-sm font-medium text-gray-800 transition-colors duration-300',
                    isOpenMenu && isCollapseGrUsersActive && 'bg-primary/90 text-white hover:bg-primary'
                  )}
                >
                  {t('Buttons.groupUsers')}
                  <motion.span className="ml-2" initial={false} animate={{ rotate: isCollapseGrUsers ? 0 : 180 }}>
                    <ChevronDown
                      className={cn('w-5 text-gray-800', isOpenMenu && isCollapseGrUsersActive && 'text-white')}
                    />
                  </motion.span>
                </p>

                <motion.p
                  className="flex flex-col bg-white text-black"
                  initial={false}
                  style={{ overflow: 'hidden' }}
                  animate={{ transition: { type: 'tween' }, height: isCollapseGrUsers ? 0 : isOpenMenu ? 'auto' : 0 }}
                >
                  {checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_GUARDIANS]) && (
                    <LeftSideNavSubitem
                      onClick={() => setIsCollapseGrUsers((prev) => !prev)}
                      title={t('Buttons.guardians')}
                      path={'/dashboard/all-guardians'}
                    />
                  )}
                  {checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_THERAPISTS]) && (
                    <LeftSideNavSubitem
                      onClick={() => setIsCollapseGrUsers((prev) => !prev)}
                      title={t('Buttons.therapists')}
                      path={'/dashboard/all-therapists'}
                    />
                  )}
                  {checkPermissions([PERMISSIONS_TYPES.VIEW_ADMIN_USER_LIST]) && (
                    <LeftSideNavSubitem
                      onClick={() => setIsCollapseGrUsers((prev) => !prev)}
                      title={t('Buttons.admins')}
                      path={'/dashboard/admin-users-list'}
                    />
                  )}
                </motion.p>
              </div>
            }
          </li>
        ) : null}

        <LeftSideNavItem
          openedMenu={isOpenMenu}
          path={'/dashboard/all-shifts'}
          icon={CalendarCircle}
          title={t('Buttons.shifts')}
        />
        {checkPermissions([PERMISSIONS_TYPES.MY_SCHEDULE]) && (
          <LeftSideNavItem
            openedMenu={isOpenMenu}
            path={'/dashboard/my-schedule'}
            icon={CalendarNav}
            title={t('Buttons.schedule')}
          />
        )}
        {checkPermissions([PERMISSIONS_TYPES.VIEW_FULL_SCHEDULE]) && (
          <LeftSideNavItem
            openedMenu={isOpenMenu}
            path={'/dashboard/schedule'}
            icon={CalendarNav}
            title={t('Buttons.fullSchedule')}
          />
        )}
        {checkPermissions([PERMISSIONS_TYPES.VIEW_ALL_DOCUMENTS]) && (
          <LeftSideNavItem
            openedMenu={isOpenMenu}
            path={'/dashboard/all-documents'}
            icon={Document}
            title={t('Buttons.documents')}
          />
        )}
        {checkPermissions([PERMISSIONS_TYPES.VIEW_MY_DOCUMENTS]) && (
          <LeftSideNavItem
            openedMenu={isOpenMenu}
            path={'/dashboard/my-documents'}
            icon={Document}
            title={t('Buttons.myDocuments')}
          />
        )}
        <LeftSideNavItem
          openedMenu={isOpenMenu}
          path={'/dashboard/chats'}
          icon={unreadMsgCount > 0 ? MessageNotif : Message}
          title={t('Buttons.chats')}
        />
      </ul>
    </motion.div>
  );
}
