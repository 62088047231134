import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

type Request = {
  id: string;
  text: string;
};

export type Options = Omit<UseMutationOptions<string, DefaultError, Request>, 'mutationKey' | 'mutationFn'>;

export const useUpdateCommentMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async ({ id, text }: Request): Promise<string> => {
    const { data } = await axiosInterceptorInstance.put(`comments/${id}`, { text });
    return data;
  };

  return useMutation({
    mutationKey: ['comments'],
    mutationFn: _mutation,
    onSettled: (data, error) => {
      if (!error) {
        queryClient.invalidateQueries({ queryKey: ['comments'] });
      }
    },
    ...options,
  });
};
