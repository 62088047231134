import { Chips } from '../chips';
import dayjs from '@/lib/dayjsConfig';
import { CommentData } from '@/hooks/fetchers/queries/useCommentsQuery';
import { compileFullName } from '@/lib/utils';
import { USER_ROLES_COLORS } from '@/common/constants';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui';
import { EllipsisVertical } from 'lucide-react';
import { useTranslations } from 'next-intl';
import { ConfirmDialog, ConfirmDialogRef } from '../confirm-dialog';
import { useRef } from 'react';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';

type Props = {
  comment: CommentData;
  onEdit: (comment: CommentData | null) => void;
  onRemove: (commentId: string) => void;
};

export const Comment = ({ comment, onEdit, onRemove }: Props) => {
  const t = useTranslations();
  const { checkPermissions } = useIsAllowed();
  const confirmDialogRef = useRef<ConfirmDialogRef>();
  const parseDate = (date: string) => {
    return dayjs(date).format('MM.DD.YYYY HH:mm');
  };

  const handleEdit = (comment: CommentData) => {
    onEdit(comment);
  };

  const removeComment = (id: string) => {
    onRemove(id);
  };

  const handleDelete = (comment: CommentData) => {
    confirmDialogRef.current?.open({
      description: t('Toasts.confirmDeleteComment'),
      value: comment.id,
    });
  };

  return (
    <>
      <div className="rounded-sm border border-gray-200 p-4">
        <header className="flex flex-row items-start justify-between border-b border-gray-200 pb-4">
          <div className="flex flex-row items-center">
            <span className="mr-2 text-base font-semibold text-gray-950">{compileFullName(comment.author)}</span>
            <Chips color={USER_ROLES_COLORS[comment.author.role]} title={comment.author.role} />
          </div>
          <div className="flex flex-row items-center space-x-2">
            <span className="text-xs text-gray-600">{comment.contentEditedAt ? `${parseDate(comment.updatedAt)} (edited)` : parseDate(comment.createdAt)}</span>
            {checkPermissions([PERMISSIONS_TYPES.EDIT_COMMENTS, PERMISSIONS_TYPES.REMOVE_COMMENTS]) && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="icon">
                    <EllipsisVertical className="w-5 text-gray-400" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuGroup>
                    <DropdownMenuItem className="cursor-pointer" onClick={() => handleEdit(comment)}>
                      {t('Buttons.edit')}
                    </DropdownMenuItem>

                    <DropdownMenuItem className="cursor-pointer" onClick={() => handleDelete(comment)}>
                      {t('Buttons.delete')}
                    </DropdownMenuItem>
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </header>
        <div className="pt-4 text-sm text-gray-800">{comment.text}</div>
      </div>
      <ConfirmDialog
        ref={confirmDialogRef}
        onConfirm={(id) => removeComment(id)}
        title={t('Common.deleteComment')}
        type="delete"
      />
    </>
  );
};
