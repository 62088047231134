'use client';
import { Comment } from './comment';
import { CommentData, useCommentsQuery } from '@/hooks/fetchers/queries/useCommentsQuery';
import { useCreateCommentMutation } from '@/hooks/fetchers/mutations/comments/useCreateCommentMutation';
import { useEffect, useRef, useState } from 'react';
import { cn } from '@/lib/utils';
import { useRemoveCommentMutation } from '@/hooks/fetchers/mutations/comments/useRemoveCommentMutation';
import { toast, ToastTypeEnums } from '../toast';
import { useTranslations } from 'next-intl';
import { useUpdateCommentMutation } from '@/hooks/fetchers/mutations/comments/useUpdateCommentMutation';
import { CommentForm } from './comments-form';

type Props = {
  serviceId: string;
  scrollAreaClassName?: string;
};

export const CommentsList = ({ serviceId, scrollAreaClassName }: Props) => {
  const t = useTranslations();
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const [editComment, setEditComment] = useState<CommentData | null>(null);
  const { data: comments, refetch: refetchComments, isFetched } = useCommentsQuery(serviceId);

  const { mutate: createComment, isPending: isCreating } = useCreateCommentMutation({
    onSuccess: () => refetchComments(),
  });

  const { mutate: removeComment, isPending: isPendingRemove } = useRemoveCommentMutation({
    onSuccess: () => {
      toast({
        title: t('Toasts.success'),
        typeIcon: ToastTypeEnums.SUCCESS,
        description: t('Toasts.successDeleted'),
      });
      refetchComments();
      if (editComment) {
        setEditComment(null);
      }
    },
  });

    const { mutate: updateComment, isPending: isPendingUpdating } = useUpdateCommentMutation({
      onSuccess: () => {
        toast({
          title: t('Toasts.success'),
          typeIcon: ToastTypeEnums.SUCCESS,
          description: t('Toasts.commentUpdated'),
        });
        refetchComments();
      },
    });

  const addComment = (text: string, id?: string) => {
    if (id) {
      if (!isPendingUpdating) {
        updateComment({ id, text});
      }
    } else {
      createComment({ serviceId, text });
    }
  };

  const handleEditMode = (comment: CommentData | null) => {
    setEditComment(comment);
  };

  const handleRemove = (commentId: string) => {
    if (!isPendingRemove) {
      removeComment(commentId);
    }
  };

  const scrollToBottom = (isSmooth: boolean = false) => {
    scrollRef.current?.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: isSmooth ? 'smooth' : 'instant',
    });
  };

  useEffect(() => {
    setTimeout(() => scrollToBottom(), 0);
  }, [isFetched]);

  useEffect(() => {
    if (isFetched) {
      setTimeout(() => scrollToBottom(true), 100);
    }
  }, [comments]);

  return (
    <div className="mt-4">
      <div ref={scrollRef} className={cn('h-[400px] space-y-4 overflow-auto', scrollAreaClassName)}>
        {comments?.map((comment, index) => (
          <Comment onRemove={handleRemove} onEdit={handleEditMode} key={index} comment={comment} />
        ))}
      </div>
      <CommentForm
        editCommentCancel={() => setEditComment(null)}
        editComment={editComment}
        isPending={isCreating}
        onSubmit={addComment}
      />
    </div>
  );
};
