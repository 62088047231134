import axiosInterceptorInstance from '@/axiosInterceptorInstance';
import { DefaultError, UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query';

type Response = {
  id: string;
};

export type Options = Omit<
  UseMutationOptions<Response, DefaultError, string>,
  'mutationKey' | 'mutationFn'
>;

export const useRemoveCommentMutation = (options?: Options) => {
  const queryClient = useQueryClient();
  const _mutation = async (id: string): Promise<Response> => {
    const { data } = await axiosInterceptorInstance.delete(`comments/${id}`);
    return data;
  };

  return useMutation({
    mutationKey: ['comments'],
    onSettled: ((data, error) => {
      if (!error) {
        queryClient.resetQueries({ queryKey: ['comments'] });
      }
    }),
    mutationFn: _mutation,
    ...options,
  });
};
