'use client';
import { cn } from '@/lib/utils';
import { Logo } from '@/components/svg';
import { HeaderNavItem } from './header-nav-item';
import { HeaderActionsButtons } from './header-actions-buttons';
import { useIsAllowed } from '@/lib/RBAC';
import { PERMISSIONS_TYPES } from '@/lib/RBAC/enums/permissions-types';
import { useRouter } from 'next/navigation';
import { useTranslations } from 'next-intl';
import {
  Menubar,
  MenubarContent,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from '@/components/ui/menubar';
import { HeaderNavMenuItem } from './header-nav-menu-item';

interface HeaderProps {
  className?: string;
}

export function Header({ className, ...props }: HeaderProps) {
  const CALLBACK_URL = `${process.env.NEXT_PUBLIC_LOGIN_URL}`;
  const { checkPermissions } = useIsAllowed();
  const t = useTranslations();
  const router = useRouter();

  return (
    <header
      className={cn(
        'flex w-full select-none flex-row items-center justify-between border border-b-zinc-200 px-4',
        className
      )}
      {...props}
    >
      <div className="w-20">
        <Logo width="26" height="28" className="cursor-pointer" onClick={() => router.push('/dashboard')} />
      </div>

      <nav>
        <ul className="flex flex-row space-x-9 font-medium">
          {checkPermissions([PERMISSIONS_TYPES.VIEW_ACTIVITY_LOGS]) && (
            <HeaderNavItem link={{ text: t('Pages.AuditLog.title'), href: '/dashboard/audit-log' }} />
          )}
          {checkPermissions([PERMISSIONS_TYPES.VIEW_LIST_OF_SERVICE_ROLES]) && (
            <HeaderNavItem link={{ text: t('Pages.ServiceRoles.title'), href: '/dashboard/service-roles' }} />
          )}
          {checkPermissions([PERMISSIONS_TYPES.VIEW_TIMESHEETS_LIST]) && (
            <HeaderNavItem link={{ text: t('Pages.Timesheets.title'), href: '/dashboard/timesheets' }} />
          )}
          {checkPermissions([PERMISSIONS_TYPES.VIEW_REPORTS]) && (
            <li className="flex flex-row items-center">
              <Menubar>
                <MenubarMenu>
                  <MenubarTrigger className="flex h-14 cursor-pointer items-center rounded-[2px] border-b-2 border-transparent text-sm font-medium text-gray-400 transition-colors duration-300 hover:border-primary hover:text-primary">
                    {t('Common.reports')}
                  </MenubarTrigger>
                  <MenubarContent>
                    <HeaderNavMenuItem
                      link={{ text: t('Pages.InitialVisits.title'), href: '/dashboard/initial-visits' }}
                    />
                    <MenubarSeparator />
                    <HeaderNavMenuItem link={{ text: t('Pages.Frequency.title'), href: '/dashboard/frequency' }} />
                    <MenubarSeparator />
                    <HeaderNavMenuItem link={{ text: t('Pages.Performance.title'), href: '/dashboard/performance' }} />
                    <MenubarSeparator />
                    {/* <HeaderNavMenuItem
                      link={{ text: t('Pages.UnsignedShifts.title'), href: '/dashboard/unsigned-shifts' }}
                    />
                    <MenubarSeparator /> */}
                    <HeaderNavMenuItem link={{ text: t('Pages.Billing.title'), href: '/dashboard/billing' }} />
                  </MenubarContent>
                </MenubarMenu>
              </Menubar>
            </li>
          )}
        </ul>
      </nav>

      <div className="flex flex-row items-center space-x-1">
        <HeaderActionsButtons callbackUrl={CALLBACK_URL} />
      </div>
    </header>
  );
}
