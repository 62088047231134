import { formatPhone, guessCountryByPartialNumber, MASK_CHAR } from '@/components/form';

export const toMaskByString = (phone: string) => {
  const countryGuessResult = guessCountryByPartialNumber({ phone });
  if (countryGuessResult && countryGuessResult.country) {
    const { format: mask, dialCode } = countryGuessResult.country;
    const value = formatPhone(phone, {
      prefix: '+',
      mask: mask ? (mask as string) : '............',
      maskChar: MASK_CHAR,
      dialCode,
      charAfterDialCode: ' ',
    });
    return value;
  }
  return phone;
};
