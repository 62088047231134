import { ChevronLeft, ChevronRight } from 'lucide-react';
import { cn } from '@/lib/utils';

interface PaginationProps {
  className?: string;
  totalSize: number;
  sizePerPage: number;
  currentPage: number;
  changeCurrentPage: (pageNum: number) => void;
}

interface PageProps {
  className?: string;
  label: any;
  pageNum: number;
  changeCurrentPage: (pageNum: number) => void;
}

interface PaginationElementsProps {
  currentPage: number;
  numberOfPagesNextToActivePage: number;
  pageNum: number;
  changeCurrentPage: (pageNum: number) => void;
}

function PaginationElements({
  currentPage,
  numberOfPagesNextToActivePage,
  pageNum,
  changeCurrentPage,
}: PaginationElementsProps) {
  let current = currentPage,
    last = pageNum,
    delta = numberOfPagesNextToActivePage,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithEllipsis = [],
    l = undefined,
    isEllipsisIncludes = false;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (l && i - l !== 1) {
      rangeWithEllipsis.push(
        <span
          key={isEllipsisIncludes ? -1 : 0}
          className="relative inline-flex select-none items-center px-4 py-2 text-sm font-semibold text-gray-400 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
        >
          ...
        </span>
      );
      isEllipsisIncludes = true;
    }
    rangeWithEllipsis.push(
      <a
        key={i}
        className={cn(
          'relative inline-flex cursor-pointer select-none items-center px-4 py-2 text-sm font-semibold text-gray-400 ring-1 ring-inset ring-gray-300 transition-colors duration-300 hover:bg-secondary focus:z-20 focus:outline-offset-0',
          currentPage === i && 'pointer-events-none bg-secondary text-blue-600'
        )}
        onClick={(e) => {
          e.preventDefault();
          changeCurrentPage(i);
        }}
      >
        {i}
      </a>
    );
    l = i;
  }
  return rangeWithEllipsis;
}

function Page({ className, label, pageNum, changeCurrentPage }: PageProps) {
  return (
    <a
      onClick={() => changeCurrentPage(pageNum)}
      aria-current="page"
      className={cn(
        'relative inline-flex cursor-pointer select-none items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 transition-colors duration-300 hover:bg-secondary focus:z-20 focus:outline-offset-0',
        className
      )}
    >
      {label}
    </a>
  );
}

export function Pagination({ className, totalSize, sizePerPage, currentPage, changeCurrentPage }: PaginationProps) {
  let pageNum = Math.ceil(totalSize / sizePerPage);

  return (
    <div className={cn('flex items-center justify-center', className)}>
      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
        <Page
          changeCurrentPage={(page) => changeCurrentPage(page)}
          className={cn('rounded-l-md', currentPage === 1 && 'pointer-events-none bg-gray-300 text-gray-100')}
          label={<ChevronLeft className="size-5"></ChevronLeft>}
          pageNum={currentPage - 1}
        />

        <PaginationElements
          currentPage={currentPage}
          numberOfPagesNextToActivePage={1}
          changeCurrentPage={(page) => changeCurrentPage(page)}
          pageNum={pageNum}
        />

        <Page
          changeCurrentPage={(page) => changeCurrentPage(page)}
          className={cn('rounded-r-md', currentPage === pageNum && 'pointer-events-none bg-gray-300 text-gray-100')}
          label={<ChevronRight className="size-5"></ChevronRight>}
          pageNum={currentPage + 1}
        />
      </nav>
    </div>
  );
}
