import { authOptions } from '@/authOptions';
import { ALLOWED_PERMISSIONS } from '../constants/allowed-premissions';
import { PERMISSIONS_TYPES } from '../enums/permissions-types';
import { ROLE_TYPES } from '../enums/role-types';
import { getServerSession } from "next-auth/next";


export function isAllowed(role: ROLE_TYPES, permissions: PERMISSIONS_TYPES[]): boolean {
  if (role) {
    if (ALLOWED_PERMISSIONS[role]) {
      const allowed = ALLOWED_PERMISSIONS[role].filter(value => permissions.includes(value));
      return allowed.length === permissions.length;
    } else {
      console.log('DEBUG LOG: WRONG ROLE TYPE!!!', role);
      return false;
    }
  }
  return false;
}

export async function allowedForRolesServer(roles: ROLE_TYPES[]): Promise<boolean> {
  if (roles && roles.length > 0) {
    const session = await getServerSession(authOptions);
    if (session && session.user && session.user.roles) {
      return Promise.resolve(roles.includes(session.user.roles[0]));
    } else {
      console.log('DEBUG LOG: WRONG SESSION DATA');
      return Promise.resolve(false);
    }
  }
  return Promise.resolve(false);
}
