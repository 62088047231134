import { guessCountryByPartialNumber } from '@/components/form';

export const validatePhone = (phone: string) => {
  const countryGuessResult = guessCountryByPartialNumber({ phone });
  if (countryGuessResult && countryGuessResult.country) {
    const { format: mask, dialCode } = countryGuessResult.country;

    if (typeof mask !== 'string') return false;

    const dots = mask.match(/\./g);
    const regex = new RegExp(`\\+${dialCode}\\d{${dots ? dots.length : 10}}`, 'g');

    return regex.test(phone);
  }
  return false;
};
