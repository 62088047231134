'use client';
import { createContext, useContext, useEffect, useState } from 'react';
import SendbirdChat, { ConnectionHandler } from '@sendbird/chat';
import { GroupChannelModule } from '@sendbird/chat/groupChannel';
import { SendbirdChatWith } from '@sendbird/chat';
import { useSession } from 'next-auth/react';

interface SendBirdComContextType {
  sb: SendbirdChatWith<GroupChannelModule[]> | null;
  isLoaded: boolean;
}

const SendBirdComContext = createContext<SendBirdComContextType | undefined>(undefined);

type Props = {
  children?: React.ReactNode;
};

const APP_ID = `${process.env.NEXT_PUBLIC_SENDBIRD_APP_ID}`;

export function SendBirdComProvider({ children }: Props) {
  const [sb, setSb] = useState<{
    sb: SendbirdChatWith<GroupChannelModule[]> | null;
    isLoaded: boolean;
  }>({ sb: null, isLoaded: false });

  const { data: session } = useSession();

  useEffect(() => {
    async function initSendBird(userId: string, sendBirdToken: string) {
      const { sb, isLoaded } = await init(userId, sendBirdToken);
      setSb({ sb, isLoaded });
    }
    if (session && session.user.userId && session.user.sendBird && session.user.sendBird.token) {
      initSendBird(session.user.userId, session.user.sendBird.token);
    }
  }, [session?.user.userId]);

  const init = async (
    userId: string,
    sendBirdToken: string
  ): Promise<{
    sb: SendbirdChatWith<GroupChannelModule[]> | null;
    isLoaded: boolean;
  }> => {
    if (session) {
      try {
        const sendBirdChat = await SendbirdChat.init({
          appId: APP_ID,
          localCacheEnabled: true,
          modules: [new GroupChannelModule()],
        });
        await sendBirdChat.connect(userId, sendBirdToken);
        await sendBirdChat.setChannelInvitationPreference(true);
        console.log(`%c DEBUG LOG: INIT SENDBIRD SUCCESS`, 'color: green');

        const connectionHandler = new ConnectionHandler({
          onConnected: () => {
            console.log(`%c DEBUG LOG: SENDBIRD CONNECTED`, 'color: green');
          },
          onDisconnected: () => {
            console.log(`%c DEBUG LOG: SENDBIRD DISCONNECTED`, 'color: red');
          },
          onReconnectStarted: () => {
            console.log(`%c DEBUG LOG: SENDBIRD RECONNECTED STARTED`, 'color: yellow');
          },
          onReconnectSucceeded: () => {
            console.log(`%c DEBUG LOG: SENDBIRD RECONNECTED SUCCEEDED`, 'color: green');
          },
          onReconnectFailed: () => {
            console.log(`%c DEBUG LOG: SENDBIRD RECONNECTED FAILED`, 'color: red');
          },
        });

        sendBirdChat.addConnectionHandler('UNIQUE_HANDLER_ID', connectionHandler);

        return {
          sb: sendBirdChat,
          isLoaded: true,
        };
      } catch (error) {
        console.log(`%c DEBUG LOG: ERROR SENDBIRD INIT`, 'color: yellow');
        console.log(error);
        return {
          sb: null,
          isLoaded: false,
        };
      }
    }
    return {
      sb: null,
      isLoaded: false,
    };
  };

  return <SendBirdComContext.Provider value={sb}>{children}</SendBirdComContext.Provider>;
}

export const useSendBirdInstance = (): SendBirdComContextType => {
  const context = useContext(SendBirdComContext);
  if (!context) {
    throw new Error('useSendBirdInstance must be used within a SendBirdComProvider');
  }
  return context;
};
